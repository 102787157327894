body {
  font-family: "Lucida Console", "Courier New", monospace;
}

.light-wrapper {
  min-height: 1080px;
  background: white;
  color: #212529;
}

.dark-wrapper {
  min-height: 1080px;
  background: #171a1c;
  color: #d0d2d4;
}

p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  p {
    font-size: 0.9rem;
  }
}

h2 {
  font-size: 2rem;
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.2rem;
  }
}

.video-h3 {
  /* text-align: left; */
  font-size: 1.3rem;
  font-weight: 600;
  color: #212529;
}

@media (max-width: 1399px) {
  .video-h3  {
    font-size: 1.1rem;
  }
}

@media (max-width: 1199px) {
  .video-h3  {
    font-size: 1rem;
  }
}

@media (max-width: 442px) {
  .video-h3  {
    font-size: 0.9rem;
  }
}

@media (max-width: 377px) {
  .video-h3  {
    font-size: 0.7rem;
  }
}

div {
  font-size: 1rem;
}

@media (max-width: 768px) {
  div {
    font-size: 0.9rem;
  }
}

option {
  font-size: 1rem;
}

@media (max-width: 768px) {
  option {
    font-size: 0.8rem;
  }
}

.light-modal-header {
  padding: 10px;
  background: white;
  color: #212529;
}

.dark-modal-header {
  padding: 10px;
  background: #212529;
  color: #d0d2d4;
}

.light-modal-body {
  padding: 10px;
  background: white;
  color: #212529;
}

.dark-modal-body {
  padding: 10px;
  background: #212529;
  color: #d0d2d4;
}

/* .light-form {
  color: #212529;
  background: white;
  border: 1px solid #212529;
}

.dark-form {
  color: #d0d2d4;
  background: #212529;
  border: 1px solid #dadada;
} */

/*---------------------------------*/


/*----table create/edit quiz--------------*/

.lang-btn {
  color: white
}


.navbar-img {
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  object-fit: cover;
}

.nav-btn {
  text-align: center;
  margin-left: 0.7em;
  background: #212529;
  width: 2em;
  border-radius: 20%;
  outline: 0.15em solid #212529;
}

.nav-btn-act {
  text-align: center;
  margin-left: 0.7em;
  background: #212529;
  width: 2em;
  border-radius: 20%;
  outline: 0.15em solid white;
}

.light-stop-quiz-btn {
  border-radius: 5px;
  background: white;
  border: none;
  text-align: center;
  padding: 0.1% 0.5%;
  margin: 0.2% 0;
  color: #212529;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}

.dark-stop-quiz-btn {
  border-radius: 5px;
  background: #212529;
  text-align: center;
  padding: 0.1% 0.5%;
  margin: 0.2% 0;
  color: #d0d2d4;
  border: 1px solid #d0d2d4;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}

.light-footer {
  position: fixed;
  padding-top: 5px;
  border-top: 1px solid #dbdbdc;
  left: 0;
  bottom: -10px;
  width: 100%;
  background-color: #eff0f1;
  text-align: center;
}

.dark-footer {
  position: fixed;
  padding-top: 5px;
  border-top: 1px solid black;
  left: 0;
  bottom: -10px;
  width: 100%;
  background-color: #2c3034;
  text-align: center;
}

.light-footer-links {
  color: #074f78;
  text-decoration: underline;
  line-height: 90%;
  cursor: pointer;
}

.dark-footer-links {
  color: #4da2f7;
  text-decoration: underline;
  line-height: 90%;
  cursor: pointer;
}

.light-footer-copyright {
  color: #212529;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: -20px;
  font-size: 0.7rem;
  line-height: 90%;
}

@media (max-width: 768px) {
  .light-footer-copyright {
    font-size: 0.5rem
  }
}

.dark-footer-copyright {
  color: #d0d2d4;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: -20px;
  font-size: 0.7rem;
  line-height: 90%;
}

@media (max-width: 768px) {
  .light-footer-copyright {
    font-size: 0.5rem
  }
}

.social-icons {
  margin-top: 35px;
  padding: 0 90px 0 90px;
}

@media (max-width: 1200px) {
  .social-icons {
    padding: 0 50px 0 50px;
  }
}

@media (max-width: 420px) {
  .social-icons {
    padding: 0;
    /* margin-top: 35px; */
  }
}

.quiz-title-table {
  width: 100%;
  margin-top: 15px;
  text-align: left;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

td.table-fontsize {
  font-size: 1rem;
  text-align: left;
}

@media (max-width: 768px) {
  td.table-fontsize {
    font-size: 0.8rem
  }
}

.details-btn {
  background: none;
  color: red;
  border: 1px solid red;
  padding: 0 1%;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 700;
}

.quiz-table {
  /* border: 1px solid #babfc4; */
  border-bottom: 1px solid #babfc4;
  border-right: 1px solid #babfc4;
  margin: auto;
  height: 300px;
  width: 100%;
  overflow: auto;
  /* resize: both; */
  resize: vertical;
  padding: 1%;
}

/* @media (max-width: 768px) {
  .quiz-table {
    width: 100%;
    height: 350px;
    resize: vertical;
  }
} */


.quiz-table-edit {
  /* border: 1px solid #babfc4; */
  margin: auto;
  height: 550px;
  width: 640px;
  overflow: auto;
  resize: both;
  padding: 1%;
}

@media (max-width: 768px) {
  .quiz-table-edit {
    width: 100%;
    height: 300px;
    resize: vertical;
  }
}


.data-table {
  width: 635px;
  padding: 10px;
}

.user-cards {
  cursor: pointer;
  font-weight: 500;
}

.light-card-user {
  font-size: 1.2rem;
  color: #212529;
}

@media (max-width: 768px) {
  .light-card-user {
    font-size: 0.9rem;
    /* padding: 0 0.5%; */
  }
}

.dark-card-user {
  font-size: 1.2rem;
  color: #d0d2d4;
}

@media (max-width: 768px) {
  .dark-card-user {
    font-size: 0.9rem;
    /* padding: 0 0.5%; */
  }
}

input[type="radio"] {
  border-color: #676d74;
}

.card-membership {
  background: white;
  text-align: center;
  padding: 2%;
  border-radius: 10px;
  border: 1px solid #dadada;
  color: #212529;
  font-size: 1.1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.card-membership-guru-big {
  background: white;
  text-align: center;
  margin-top: 25px;
  padding: 1%;
  border-radius: 10px;
  border: 1px solid #767676;
  color: #212529;
  font-size: 1.1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

/* .library {
  width: 100%;
  border: none;
  text-align: center;
  max-height: 550px;
  overflow-x: hidden;
} */

.faq-div {
  margin-top: 30px;
  font-size: 1.2rem;
  font-weight: 600;
}

@media (max-width: 768px) {
  .faq-div {
    margin-top: 25px;
    font-size: 1rem;
  }
}

.tab {
  margin-top: 20px;
  text-align: center;
}

.sub-quiz {
  text-align: center;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .sub-quiz {
    margin-top: 10px;
  }
}

.card-membership-guru {
  background: white;
  text-align: center;
  padding: 2%;
  border: 1px solid #dadada;
  color: #212529;
  font-size: 1.1rem;
  margin: 2% 0 2% 0
}

.card-conditions {
  background: white;
  text-align: center;
  padding: 5% 2% 2% 2%;
  color: #212529;
  font-size: 1.1rem;
}

@media (max-width: 992px) {
  .card-conditions {
    padding: 2%;
  }
}


.home-header {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  margin: 20px 0 20px 0;
}

@media (max-width: 768px) {
  .home-header {
    font-size: 1.8rem;
    margin: 15px 0 15px 0;
  }
}

.header {
  text-align: center;
  margin: 30px 0 30px 0;
}

@media (max-width: 768px) {
  .header {
    font-size: 1.2rem;
    margin: 15px 0 15px 0;
  }
}

.header-tq {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 15px 0 15px 0;
}

@media (max-width: 768px) {
  .header-tq {
    font-size: 1rem;
    margin: 15px 0 15px 0;
  }
}

.header-tq-title {
  font-size: 2rem;
  font-weight: 600;
  margin: 15px 0 15px 0;
}

@media (max-width: 768px) {
  .header-tq-title {
    font-size: 1.2rem;
    margin: 15px 0 15px 0;
  }
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 25px; */
}

.light-congrat {
  color: green;
  font-weight: 600;
}

.dark-congrat {
  color: rgb(3, 246, 3);
  font-weight: 600;
}

.tic {
  font-size: 1.2rem;
  color: green;
  font-weight: 600;
}

.tic-small {
  color: blue;
  font-weight: 600;
}

.membership {
  font-weight: 700;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  background: #212529;
  color: #d0d2d4;
  border: none;
  border-radius: 10px;
  padding: 1%;
  margin-bottom: 15px;
}

.conditions {
  text-align: left;
  font-size: 1rem;
  line-height: 1rem;
  margin-left: 5%;
}

.guru-conditions {
  text-align: left;
  font-size: 1rem;
  line-height: 1rem;
  margin-left: 5%;
  padding-top: 3%;
}

@media (max-width: 992px) {
  .guru-conditions {
    padding: 1% 0 3% 0;
  }
}

.price-area {
  text-align: center;
  margin-top: 20px;
  border-top: 2px solid #767676;
  color: #212529;
  padding-top: 3%;
}

.price-area-guru {
  text-align: center;
  margin-top: 20px;
  /* border-top: 2px solid #767676; */
  color: #212529;
  padding-top: 3%;
}

.price {
  font-weight: 700;
  color: green;
  font-size: 1.5rem;
  text-align: left;
}

.free-stand {
  font-weight: 700;
  color: blue;
  font-size: 3.2rem;

}

.free-guru {
  font-weight: 700;
  padding-top: 35px;
  text-align: center;
  color: blue;
  font-size: 3.2rem;
  /* border-top: 2px solid #767676; */
}

.light-rec {
  color: green;
  font-weight: 700;
}

.dark-rec {
  color: #14c307;
  font-weight: 700;
  opacity: 0.8;
}

.unrec {
  color: red;
  font-weight: 700;
}


.light-edit-prof-btn {
  background: white;
  color: #212529;
  margin: 10px;
  border: 1px solid #dadada;
  padding: 0 1%;
  border-radius: 5px;
  font-size: 0.8rem;
}

.dark-edit-prof-btn {
  background: #212529;
  color: #d0d2d4;
  margin: 10px;
  border: 1px solid #d0d2d4;
  padding: 0 1%;
  border-radius: 5px;
  font-size: 0.8rem;
}

.tab-btn-link-right {
  background: none;
  text-align: center;
  padding: 1% 5%;
  border-radius: 1px;
  border-left: 1px solid #babfc4;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #babfc4;
  color: #0a70d6;
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
}

@media (max-width: 390px) {
  .tab-btn-link-right {
    font-size: 0.8rem;
    padding: 1% 2%;
  }
}

.tab-btn-on-right {
  background: none;
  text-align: center;
  padding: 1% 5%;
  border-radius: 1px;
  border-left: none;
  border-top: 1px solid #babfc4;
  border-right: 1px solid #babfc4;
  border-bottom: none;
  /* color: #212529; */
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 390px) {
  .tab-btn-on-right {
    font-size: 0.8rem;
    padding: 1% 2%;
  }
}

.tab-btn-link-left {
  background: none;
  border-right: 1px solid #babfc4;
  border-bottom: 1px solid #babfc4;
  text-align: center;
  padding: 1% 5%;
  border-radius: 1px;
  border-left: none;
  border-top: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
}

@media (max-width: 390px) {
  .tab-btn-link-left {
    font-size: 0.8rem;
    padding: 1% 2%;
  }
}

.tab-btn-on-left {
  background: none;
  text-align: center;
  padding: 1% 5%;
  border-radius: 1px;
  border-left: 1px solid #babfc4;
  border-top: 1px solid #babfc4;
  border-right: none;
  border-bottom: none;
  color: #212529;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 390px) {
  .tab-btn-on-left {
    font-size: 0.8rem;
    padding: 1% 2%;
  }
}


.fav {
  background: white;
  text-align: center;
  padding: 1% 2%;
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  color: #75797d;
  cursor: pointer;
  font-weight: 700;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}


/* Used in Edit Quiz */
.add-col-row-btn {
  background: rgb(52, 144, 52);
  text-align: center;
  border-radius: 5px;
  border: none;
  color: #d0d2d4;
  font-weight: 700;
  font-size: 0.8rem;
}

/* Used in Create Quiz */
.col-row-add-btn {
  background: rgb(52, 144, 52);
  text-align: center;
  padding: 0.5rem 1rem;
  /* width: 100%; */
  /* height: 3em; */
  border-radius: 1em;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 1rem;
}

.col-row-remove-btn {
  background: red;
  text-align: center;
  padding: 0.5rem 1rem;
  /* width: 100%;
  height: 3em; */
  border-radius: 1em;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 1rem;
}

.light-video-btn {
  background: white;
  border: 1px solid #212529;
  color: #212529;
  text-align: center;
  /* width: 100%; */
  padding: 1% 2%;
  /* margin-left: 10px;
  margin-right: 10px; */
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.dark-video-btn {
  background: none;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  text-align: center;
  /* width: 100%; */
  padding: 1% 2%;
  /* margin-left: 10px;
  margin-right: 10px; */
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}


.light-q-btn {
  background: white;
  border: 1px solid #212529;
  color: #212529;
  text-align: center;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.dark-q-btn {
  background: none;
  border: 1px solid #77797c;
  color: #d0d2d4;
  text-align: center;
  padding: 3px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
}


.light-main-btn {
  background: white;
  border: 1px solid #212529;
  color: #212529;
  text-align: center;
  padding: 0 1.5%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

@media (max-width: 768px) {
  .light-main-btn {
    font-size: 0.8rem;
  }
}

.dark-main-btn {
  background: none;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  text-align: center;
  padding: 0 1.5%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .dark-main-btn {
    font-size: 0.8rem;
  }
}

.light-main-btn-inactive {
  background: white;
  border: 1px solid #bdc2c7;
  color: #bdc2c7;
  text-align: center;
  padding: 0 1.5%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: crosshair;
  font-weight: 600;
  font-size: 1rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

@media (max-width: 768px) {
  .light-main-btn-inactive {
    font-size: 0.8rem;
  }
}

.dark-main-btn-inactive {
  background: none;
  border: 1px solid #77797c;
  color: #77797c;
  text-align: center;
  padding: 0 1.5%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: crosshair;
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .dark-main-btn-inactive {
    font-size: 0.8rem;
  }
}

.light-sec-btn {
  background: white;
  border: 1px solid #767676;
  color: #212529;
  text-align: center;
  padding: 0 1.5%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 300;
  font-size: 1rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

@media (max-width: 768px) {
  .light-sec-btn {
    font-size: 0.8rem;
  }
}

.dark-sec-btn {
  background: none;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  text-align: center;
  padding: 0 1.5%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 300;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .dark-sec-btn {
    font-size: 0.8rem;
  }
}

.light-sec-btn-inactive {
  background: white;
  border: 1px solid #bdc2c7;
  color: #bdc2c7;
  text-align: center;
  padding: 0 1.5%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: crosshair;
  font-weight: 300;
  font-size: 1rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

@media (max-width: 768px) {
  .light-sec-btn-inactive {
    font-size: 0.8rem;
  }
}

.dark-sec-btn-inactive {
  background: none;
  border: 1px solid #77797c;
  color: #77797c;
  text-align: center;
  padding: 0 1.5%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: crosshair;
  font-weight: 300;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .dark-sec-btn-inactive {
    font-size: 0.8rem;
  }
}

.light-sec-small-btn {
  background: white;
  border: 1px solid #767676;
  color: #212529;
  text-align: center;
  padding: 0.5% 2%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 300;
  font-size: 0.8rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.dark-sec-small-btn {
  background: #212529;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  text-align: center;
  padding: 0.5% 1%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 300;
  font-size: 0.8rem;
}

.third-btn {
  background: #212529;
  text-align: center;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  cursor: pointer;
  font-weight: 400;
  font-size: 0.8rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.light-fourth-btn {
  background: white;
  border: 1px solid #212529;
  color: #212529;
  text-align: center;
  padding: 0 1%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.dark-fourth-btn {
  background: #212529;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  text-align: center;
  padding: 0 1%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
}

.light-small-btn {
  background: white;
  border: 1px solid #212529;
  color: #212529;
  text-align: center;
  margin-left: 5px;
  padding: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.7rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.dark-small-btn {
  background: none;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  text-align: center;
  margin-left: 5px;
  padding: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.7rem;
}


.light-full-screen-btn {
  background: white;
  border: 1px solid #7d8185;
  color: #7d8185;
  text-align: center;
  padding: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 0.7rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.dark-full-screen-btn {
  background: none;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  text-align: center;
  padding: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 0.7rem;
}


.light-download-btn {
  background: white;
  border: 1px solid #212529;
  color: #212529;
  text-align: center;
  padding: 0 2px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 0.7rem;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

@media (max-width: 768px) {
  .light-download-btn {
    font-size: 0.6rem;
    /* line-height: 0.9rem; */
  }
}

.dark-download-btn {
  background: none;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  text-align: center;
  padding: 0 2px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 0.7rem;
}

@media (max-width: 768px) {
  .dark-download-btn {
    font-size: 0.6rem;
    /* line-height: 0.7rem; */
  }
}

.light-col-num-btn {
  background: white;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #dadada;
  color: #212529;
  cursor: pointer;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.dark-col-num-btn {
  background: #212529;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  cursor: pointer;
}

.light-row-num-btn {
  background: white;
  text-align: center;
  /* padding: 1% 20%; */
  height: 100%;
  border-radius: 5px;
  border: 1px solid #dadada;
  color: #212529;
  cursor: pointer;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.dark-row-num-btn {
  background: #212529;
  text-align: center;
  /* padding: 1% 20%; */
  height: 100%;
  border-radius: 5px;
  border: 1px solid #d0d2d4;
  color: #d0d2d4;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.light-btn-tasks {
  background: none;
  color: #212529;
  border: 1px solid #dadada;
  padding: 0.5% 2% 0.5% 2%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
}

.dark-btn-tasks {
  background: #212529;
  color: #d0d2d4;
  border: 1px solid #d0d2d4;
  padding: 0.5% 2% 0.5% 2%;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
}

.buy-btn {
  background: green;
  text-align: center;
  padding: 1% 4%;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 1.2rem;
  color: #d0d2d4;
  cursor: pointer;
  font-weight: 700;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.buy-btn-inactive {
  background: #aec4ae;
  text-align: center;
  padding: 1% 4%;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  color: #d0d2d4;
  cursor: pointer;
  font-weight: 700;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* ------error */

.error {
  margin: 15px 0 0 0;
  font-weight: 600;
  color: red;
  text-align: center;
}

.error-h2 {
  font-weight: 600;
  color: red;
  margin: 20px 0 20px 0;
  font-size: 4rem;
}


@media (max-width: 576px) {
  .error-h2 {
    font-size: 3rem;
  }
}

@media (max-width: 390px) {
  .error-h2 {
    font-size: 2rem;
  }
}

.error-p {
  /* font-weight: 600; */
  font-size: 1.2rem;
}

@media (max-width: 576px) {
  .error-p {
    font-size: 1.1rem;
  }
}

@media (max-width: 390px) {
  .error-p {
    font-size: 1rem;
  }
}

.centered {
  text-align: center;
}

/*-------------Quiz buttons for answers*/

.form-sel-font-size {
  border: 1px solid #212529;
  font-weight: 600;
}

.light-up-down-btn {
  background-color: white;
  color: #212529;
  margin-left: 15px;
  border: none;
  border-radius: 4px;
  text-align: center;
  font-size: 0.8rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dark-up-down-btn {
  background-color: #212529;
  color: #d0d2d4;
  margin-left: 15px;
  border: 1px solid #d0d2d4;
  border-radius: 4px;
  text-align: center;
  font-size: 0.8rem;
}

.light-up-down-btn-inactive {
  background-color: white;
  color: #bdc2c7;
  margin-left: 15px;
  border: none;
  border-radius: 4px;
  text-align: center;
  font-size: 0.8rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dark-up-down-btn-inactive {
  background-color: #212529;
  color: #77797c;
  margin-left: 15px;
  border: 1px solid #77797c;
  border-radius: 4px;
  text-align: center;
  font-size: 0.8rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.light-btn-question {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 15px 7px;
  background: #212529;
  text-align: center;
  margin-top: 15px;
  /* font-weight: 600; */
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.dark-btn-question {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 15px 7px;
  background: #32383e;
  border: 3px solid #474f56;
  text-align: center;
  margin-top: 15px;
  /* font-weight: 600; */
  color: #d0d2d4;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
}

.light-btn-answer {
  width: 100%;
  height: auto;
  background: white;
  color: #212529;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #dadada;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-top: 1%;
}

.dark-btn-answer {
  width: 100%;
  height: auto;
  background: #212529;
  color: #d0d2d4;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #33393f;
  margin-top: 1%;
}

.btn-answer:hover {
  background: #f2f2f2;
}

.right-answer,
.right-answer:hover {
  background: green;
  color: white;
}

.wrong-answer,
.wrong-answer:hover {
  background: red;
  color: white;
}

/*--------------------------------*/

.table-cat-body {
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 992px) {
  .table-cat-body {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .table-cat-body {
    font-size: 0.8rem;
  }
}

@media (max-width: 390px) {
  .table-cat-body {
    font-size: 0.7rem;
  }
}



/*--------------------------------*/

.accor {
  max-height: 500px;
  overflow-x: hidden;
}

.card-faq {
  text-align: left;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.card-home {
  padding: 2% 5% 5% 2%;
  margin-top: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.img-video-home {
  border-radius: 3px;
  border: 2px solid rgb(205, 205, 206);
}


.light-main-card {
  padding: 4%;
  border-radius: 5px;
  background: #f9f9f9;
}

.dark-main-card {
  padding: 4%;
  border-radius: 5px;
  background: none;
}

.table {
  width: 100%;
  margin-top: 25px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.card-quiz-result {
  text-align: center;
  padding: 2%;
  margin-top: 10px;
  border-radius: 10px;
  font-size: 1.1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.light-card-recommend {
  background: white;
  border: 1px solid #dadada;
  color: #212529;
  text-align: left;
  padding: 5%;
  margin-top: 35px;
  margin-bottom: 150px;
  border-radius: 10px;
  font-size: 1.1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.dark-card-recommend {
  background: #212529;
  border: 1px solid #33393f;
  color: #d0d2d4;
  text-align: left;
  padding: 5%;
  margin-top: 35px;
  margin-bottom: 150px;
  border-radius: 10px;
  font-size: 1.1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.light-card-btn {
  background: none;
  border: 1px solid #212529;
  color: #212529;
  width: 25%;
  height: 100%;
  text-align: center;
  padding: 0 1%;
  border-radius: 10px;
  margin-left: 3%;
  font-size: 0.8rem;
}

@media (max-width: 576px) {
  .light-card-btn {
    font-size: 0.7rem;
    border-radius: 5px;
    width: 30%;
    padding: none;
    margin-left: 2%;
    padding: 0 0.5%;
  }
}

@media (max-width: 390px) {
  .light-card-btn {
    font-size: 0.5rem;
    border-radius: 5px;
    width: 30%;
    padding: none;
    margin-left: 2%;
    padding: 0 0.5%;
  }
}

.dark-card-btn {
  background: none;
  border: 1px solid #adb5bd;
  color: #adb5bd;
  width: 25%;
  height: 100%;
  text-align: center;
  padding: 0 1%;
  border-radius: 10px;
  margin-left: 3%;
  font-size: 0.8rem;
}

@media (max-width: 576px) {
  .dark-card-btn {
    font-size: 0.7rem;
    border-radius: 5px;
    width: 30%;
    padding: none;
    margin-left: 2%;
    padding: 0 0.5%;
  }
}

@media (max-width: 390px) {
  .dark-card-btn {
    font-size: 0.5rem;
    border-radius: 5px;
    width: 30%;
    padding: none;
    margin-left: 2%;
    padding: 0 0.5%;
  }
}

.card-text {
  font-size: 1.2em;
  text-align: right;
}

@media (max-width: 1199px) {
  .card-text {
    font-size: 1.1em;
  }
}

@media (max-width: 576px) {
  .card-text {
    font-size: 1em;
    margin-top: 1em;
  }
}

@media (max-width: 390px) {
  .card-text {
    font-size: 0.8em;
    margin-top: 2em;
  }
}


.card-icon {
  /* margin-left: 0.5em;
  margin-top: 0.5em; */
  width: 45px;
  height: 45px;
  /* border-radius: 20%; */
  /* background: white;
  outline: 1px solid #212529; */
  object-fit: cover;
}

@media (max-width: 992px) {
  .card-icon {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 576px) {
  .card-icon {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 350px) {
  .card-icon {
    width: 30px;
    height: 30px;
  }
}

.light-edit-icon {
  width: 30px;
  padding: 10%;
  border: 1px solid #d1d1d0;
  background: white;
  border-radius: 25%;
}

.dark-edit-icon {
  width: 30px;
  padding: 10%;
  border: 1px solid #676c70;
  background: #212529;
  border-radius: 25%;
}

.light-link-btn {
  width: 100%;
  border: none;
  color: #0a70d6;
  font-weight: 600;
  text-align: left;
  background: none;
}

.dark-link-btn {
  width: 100%;
  border: none;
  color: #4da2f7;
  font-weight: 600;
  text-align: left;
  background: none;
}